<script>
    import { Pie, mixins } from 'vue-chartjs';
    export default {
        name: 'PieChart',
        extends: Pie,
        mixins: [mixins.reactiveProp],
        props: ['options'],  
        mounted() {

            this.addPlugin({
                id: 'xAxisLabelPlugin',
                beforeInit: function (chart) {
                    chart.data.labels.forEach(function (label, index, labelsArr) {
                        //var asset = labelsArr[index][0];
                        //labelsArr[index] = asset;
                    });
                }
            });

            this.renderChart(this.chartData, this.options);
        }    
    }
</script>